import React from "react";
import "./style.css";
import { Embed } from "./types";

const today = new Date();

interface Props {
  embed: Embed;
}

const Test: React.FC<Props> = ({
  embed = {
    // fields: {
    //   name: "Hi",
    //   value: "wwadawd",
    // },
    image: { url: "https://i.imgur.com/wSTFkRM.png" },
    title: { text: "What's this?", link: "google.com" },
    fields: [
      {
        name: "awdawd",
        value: "awdawd",
      },
    ],
    description: `
      Discohook is a free tool that sends messages with embeds to your Discord server. To do that it uses [webhooks](https://support.discord.com/hc/en-us/articles/228383668), a Discord feature that lets any application send messages to a channel.

    To send messages, you need a webhook URL, you can get one via the "Integrations" tab in your server's settings.

    Note that Discohook cannot respond to user interactions, it only sends messages when you tell it to. As such creating an automatic feed or custom commands is not possible with Discohook.
      `,
  },
}) => {
  //   const [embed, setEmbed] = React.useState<Embed>({ description: "loool" });

  return (
    <div className="discord-messages">
      <div className="discord-message">
        <div className="discord-author-avatar">
          <img
            src="https://cdn.discordapp.com/attachments/654503812593090602/665721748431306753/green.png"
            alt="Guide Bot"
          />
        </div>
        <div className="discord-message-content">
          <div>
            <span className="discord-author-info">
              <span className="discord-author-username">Guide Bot</span>
              <span className="discord-bot-tag">Bot</span>
            </span>
            <span className="discord-message-timestamp">05/23/2021</span>
          </div>
          <div className="discord-message-body"></div>
          {/* Embed */}
          <div className="discord-embed">
            <div
              className="discord-left-border"
              style={{
                backgroundColor: embed.color
                  ? embed.color.toString(16)
                  : "#ffffff",
              }}
            ></div>
            <div className="discord-embed-container">
              <div className="discord-embed-container">
                <div className="discord-embed-content">
                  <div>
                    <div className="discord-embed-author">
                      {embed.url ? (
                        <img src={embed.url} className="discord-author-image" />
                      ) : (
                        ""
                      )}
                      {embed.author ? (
                        <a href="https://discord.js.org/" target="_blank">
                          {embed.author}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    {embed.title ? (
                      embed.title.link ? (
                        <div className="discord-embed-title">
                          <a href={embed.title.link} target="_blank">
                            {embed.title.text}
                          </a>
                        </div>
                      ) : (
                        <div className="discord-embed-title">
                          {embed.title.text}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                    <div className="discord-embed-description">
                      {embed.description ? embed.description : ""}
                      <div className="discord-embed-fields">
                        {embed.fields
                          ? embed.fields.map((field, idx) => (
                              <div className="discord-embed-field">
                                <div
                                  className={
                                    field.inline
                                      ? "discord-embed-field discord-inline-field"
                                      : "discord-field-title"
                                  }
                                >
                                  {field.name}
                                </div>
                                {field.value}
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                    {embed.image ? (
                      <img
                        src={embed.image.url}
                        className="discord-embed-image"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {embed.thumbnail ? <img src={embed.thumbnail.url} /> : ""}
                </div>
                {embed.footer ? (
                  <div className="discord-embed-footer">
                    {embed.footer?.icon_url ? (
                      <img
                        src={embed.footer?.icon_url}
                        className="discord-footer-image"
                      />
                    ) : (
                      ""
                    )}
                    {embed.footer?.text ? (
                      <span>
                        <span>{embed.footer?.text}</span>
                        <span className="discord-footer-separator">•</span>
                        <span>{`${today.getMonth()}/${today.getDate()}/${today.getFullYear()}`}</span>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
