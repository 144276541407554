import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  className?: any;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "400px",
    width: "400px",
  },
}));

const NotFound: React.FC<Props> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper className={classes.paper}>
          <Grid container spacing={0} alignItems="center">
            <Grid item md={12}>
              {" "}
              <Typography variant="h2">Not Found</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default NotFound;
