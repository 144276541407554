import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "../Title";
import { useQuery } from "react-query";
import { calcTransFees } from "@torchlabs/transactions";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IOrders } from "../types";

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  dataText: {
    textShadow:
      theme.palette.type === "dark" ? "2px 2px 5px #d43322" : undefined,
  },
}));

const title = (
  <React.Fragment>
    <Typography color="inherit">
      Your sales profit this month after Stripe fees, cost of data, and split
    </Typography>
    <List component="nav" aria-label="secondary mailbox folders">
      <ListItem>
        <ListItemText primary="Stripe Fees: 2.9% + 30 cents per transaction" />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <Typography>
              <Typography>Data cost:</Typography>
              <Typography>Basic Residential 1 GB = 2$</Typography>
              <Typography>Premium Residential 1 GB = 5$ </Typography>
              <Typography>Elite Residential 1 GB = 11$</Typography>
              <Typography>ISP = 1.5$</Typography>
            </Typography>
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText primary="Sales income * your chosen profit split" />
      </ListItem>
    </List>
  </React.Fragment>
);

async function getMonthlyProfit(
  orders: IOrders[] | undefined,
  profit_percent: string,
  date: any
) {
  // console.log(now.getFullYear(), date.getFullYear());
  // console.log(date.getMonth(), now.getMonth());
  let paid = 0;
  orders!.forEach((order) => {
    const orderDate = new Date(Number(order.timestamp));
    if (
      orderDate.getFullYear() === date.getFullYear() &&
      date.getMonth() === orderDate.getMonth()
    ) {
      if (
        order.plan_name === "Giveaway" ||
        (parseFloat(order.paid) < 1 && parseFloat(order.paid) !== 0)
      )
        return;
      paid += calcTransFees(
        order.plan_name,
        order.paid,
        order.payment_status,
        order.price,
        order.bandwidth ? order.bandwidth : order.proxy_count
      );
    }
  });
  // @ts-ignore
  return paid * parseFloat(profit_percent);
}

interface Props {
  orderData: IOrders[] | undefined;
  profitPercent: string;
  date: Date;
}

const ProfitUSDMonth: React.FC<Props> = ({
  orderData,
  profitPercent,
  date,
}) => {
  const classes = useStyles();
  const monthly_profit = useQuery<number | undefined, Error>(
    ["total_monthly_profit", date],
    () => getMonthlyProfit(orderData, profitPercent, date)
  );
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={12}>
          <Title>Monthly Profit</Title>
        </Grid>
        <Grid item md={12}>
          <Typography component="p" variant="h4" className={classes.dataText}>
            {/* @ts-ignore */}$
            {monthly_profit.data?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            {`on ${monthNames[date.getMonth()]}, ${date.getFullYear()}`}
          </Typography>
        </Grid>
        <Grid container item md={12} justify="flex-end">
          <Tooltip title={title} arrow>
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProfitUSDMonth;
