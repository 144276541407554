import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { GuildProvider } from "./contexts/GuildContext";
import { PaymentProvider } from "./contexts/PaymentContext";
import { AlertProvider } from "./contexts/AlertContext";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import PrivateRoute from "./common/PrivateRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet } from "react-helmet";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useColorTheme } from "./contexts/ColorThemeContext";
import NotFound from "./common/NotFound";
import Test from "./components/Test/Test";

const queryClient = new QueryClient();

const DashAuth = () => {
  return (
    <GuildProvider>
      <PaymentProvider>
        <Dashboard />
      </PaymentProvider>
    </GuildProvider>
  );
};

const App = () => {
  const { colorTheme } = useColorTheme();

  const prefersDarkMode = useMediaQuery(
    `(prefers-color-scheme: ${colorTheme})`
  );

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#d43322",
          },
          background: {
            default: prefersDarkMode ? "#262c49" : "#ffffff",
            paper: prefersDarkMode ? "#10163a" : "#ebebeb",
          },
          tonalOffset: 0.2,
        },

        typography: {
          fontFamily: "'MADE TOMMY', sans-serif",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TorchLabs</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AlertProvider>
              <Router>
                <Switch>
                  <Route exact path="/">
                    {<Redirect to="/dashboard" />}
                  </Route>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <PrivateRoute exact path="/dashboard" component={DashAuth} />
                  <Route exact path="/404" component={NotFound} />
                  <Route exact path="/test" component={Test} />
                  <Route path="*">{<Redirect to="/404" />}</Route>
                </Switch>
              </Router>
            </AlertProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
