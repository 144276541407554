import clsx from "clsx";
import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useQuery } from "react-query";

import Chart from "../Chart";
import Orders from "../Orders";

import { store } from "../../../base";
import { useGuild } from "../../../contexts/GuildContext";
import { daysInThisMonth } from "../../../utils/formatDate";
import Profile from "../Profile";
import DataUSDMonth from "./DataUSDMonth";
import DataUSDToday from "./DataUSDToday";
import ProfitUSDMonth from "./ProfitUSDMonth";
import MonthTotalGb from "./MonthTotalGb";
import TotalUsers from "./TotalUsers";
import { IOrders } from "../types";
import Loader from "../../../common/Loader";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://torchlabs.xyz/">
        TorchLabs
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      borderRadius: 10,
    },
    fixedHeight: {
      height: 240,
    },
    fixedHeightData: {
      height: 160,
    },
    fixedHeightChart: {
      height: 300,
    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(4),
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    button: {
      display: "block",
      marginTop: theme.spacing(0),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

async function getMonthlyTransactions(
  guildId: string | undefined,
  sel_date: any
) {
  const monthDate = new Date(sel_date.getTime()).setDate(1);
  const monthStart = new Date(monthDate).setHours(0, 0, 0, 0);
  let month_trans: IOrders[] = [];
  const monthEndDate = new Date(sel_date.getTime()).setDate(
    daysInThisMonth(sel_date.getTime())
  );
  const monthEnd = new Date(monthEndDate).setHours(23, 59, 59, 59);
  console.log(monthStart);
  console.log(monthEnd);
  // console.log(monthEnd);
  const transQuery = store
    .collection("channels")
    .doc(guildId)
    .collection("transactions");
  const transMonthQuery = transQuery
    .where("timestamp", ">", monthStart)
    .where("timestamp", "<", monthEnd);
  const transMonth = await transMonthQuery.get();
  transMonth.docs.forEach((doc) => {
    month_trans.push(doc.data() as IOrders);
  });
  // let total_trans_paid = 0;
  // let total_gb = 0;
  // transToday.docs.forEach((trans) => {
  //   let band =
  //     trans.data().plan_name === "Premium Datacenter"
  //       ? trans.data().proxy_count
  //       : trans.data().bandwidth;
  //   total_trans_paid += calcTransFees(
  //     trans.data().plan_name,
  //     trans.data().paid,
  //     band,
  //     trans.data().price
  //   );
  //   if (parseFloat(trans.data().paid) > 1) {
  //     total_gb +=
  //       trans.data().plan_name === "Premium Datacenter"
  //         ? 0
  //         : parseFloat(trans.data().bandwidth);
  //   }
  // });
  return month_trans;
}

const MainData = () => {
  const temp_today = new Date();
  temp_today.setDate(1);
  // @ts-ignore
  const local_store = localStorage.getItem("torch_labs_selected_date");
  let today: Date;
  if (typeof local_store === "string") {
    today = temp_today;
    today.setMonth(parseInt(JSON.parse(local_store)));
  } else {
    today = temp_today;
  }
  // console.log(today);
  const classes = useStyles();
  const { guilds, currentGuild } = useGuild();
  const [selectedDate, setSelectedDate] = useState<any>(today);
  const [age, setAge] = React.useState(today.getMonth());
  const [open, setOpen] = React.useState(false);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown | string;
    }>
  ) => {
    let dte = new Date();
    // @ts-ignore
    dte.setMonth(event.target.value);
    dte.setDate(1);
    localStorage.setItem(
      "torch_labs_selected_date",
      JSON.stringify(event.target.value)
    );
    setSelectedDate(dte);
    //@ts-ignore
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const orders_month = useQuery<IOrders[], Error>(
    ["total_monthly_sales", selectedDate],
    () => getMonthlyTransactions(guilds![currentGuild]?.id, selectedDate)
  );

  // const handleMonthSwitch = (date: any) => {
  //   console.log(date);
  //   setSelectedDate(date);
  // };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const fixedHeightPaperData = clsx(classes.paper, classes.fixedHeightData);
  const fixedHeightPaperChart = clsx(classes.paper, classes.fixedHeightChart);

  if (orders_month.isLoading) return <Loader />;

  return (
    <div>
      <Container className={classes.container}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">
              Select Month
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
              variant="standard"
            >
              {MONTHS.map((month, idx) => (
                <MenuItem value={idx}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaperData}>
              <DataUSDToday
                guildId={guilds![currentGuild].id}
                selectedDate={selectedDate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaperData}>
              <DataUSDMonth
                value={orders_month.data}
                selectedDate={selectedDate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaperData}>
              {/* Monthly Profit */}
              <ProfitUSDMonth
                orderData={orders_month.data}
                profitPercent={guilds![currentGuild].profit_percent}
                date={selectedDate}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaperData}>
              <MonthTotalGb
                data={orders_month.data}
                selectedDate={selectedDate}
              />
            </Paper>
          </Grid>
          {/* Chart */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Orders orderData={orders_month.data} />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={classes.paper}>
              <Profile currentGuild={guilds![currentGuild]} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <TotalUsers totalUsers={guilds![currentGuild].total_users} />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper className={fixedHeightPaperChart}>
              <Chart
                ordersMonth={orders_month.data}
                selectedDate={selectedDate}
              />
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};
export default MainData;
